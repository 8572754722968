import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/root.reducer';
import { Support } from '../../data/objects/Support';
import { Parution } from '../../data/objects/Parution';
import { CouvertureSupport } from './CouvertureSupport';
import { InfoGenerales } from './InfoGenerales';
import { NameSupport } from './NameSupport';
import { MediaBrandImg } from './MediaBrandImg';
import { DescriptionSupport } from './DescriptionSupport';
import Img from 'react-image-resizer';
import { SocieteImg } from './SocieteImg';
import { Societe } from '../../data/objects/Societe';
import { GroupIds, PublicPicturesUrls } from '../../utility/Constants';
import { VerticalGraph } from '../Graphs/VerticalGraph';
import './BlockDescription.css';
import { HFGraph } from '../Graphs/HFGraph';
import { GraphOJD } from '../Graphs/GraphOJD';
import { GraphOJDMens } from '../Graphs/GraphOJDMens';

interface BlockDescriptionProps {
    supportCodeTsm: string;
    support: Support | undefined;
    parutions: Parution[] | undefined;
    societe: Societe | undefined;
    waveId: number | undefined;
    groupIds: GroupIds | undefined;
}

export const BlockDescription = (props: BlockDescriptionProps) => {
    const theme = useSelector((state: RootState) => state.theme.colors);
    const selectedEtude = useSelector((state: RootState) => state.etudes.selectedEtude);
    const questionLabelStyle = {
        textAnchor: 'middle',
        style: { transform: 'translate(0,7px)', fontSize: '12px', fill: 'white' }
    };

    return (
        <div style={{ overflow: 'hidden'}} className="AppContent">
            {props.waveId && props.support && props.groupIds &&
                <div>
                    <NameSupport supportName={props.support?.Name} />
                    <div className="containerContent">
                        <div className="row">
                            <div className="colsideleft">
                                <CouvertureSupport couvPath={props.support?.CouvPath} />
                            </div>
                            <div className="columnInfoGen" style={{ marginLeft: '20px' }} >
                                <InfoGenerales support={props.support} parutions={props.parutions ? props.parutions : []} />
                            </div>
                            <div className="column" style={{
                                borderRightWidth: '3px',
                                borderStyle: 'solid',
                                borderRightColor: theme?.ColorLastGraph,
                                borderLeftWidth: 0,
                                borderBottomWidth: 0,
                                borderTopWidth: 0
                            }}>
                                <div className="descriptionContainer">
                                    <div className="row" style={{ marginBottom: '10px' }}>
                                        <div className='colsideleftBrand'>
                                            <MediaBrandImg imgPath={props.support?.MediaBrandPath} />
                                        </div>
                                        <div className="column">
                                            <div style={{ marginTop: '20px', float: 'right' }}>
                                                <SocieteImg url={props.societe?.Logo} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="descriptionSupport">
                                        <DescriptionSupport description={props.support?.Description} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="stats">
                            <div className="row" style={{ height: '330px' }}>
                                <div className="column">
                                    <div className="graphOJDImg">
                                        <Img src={PublicPicturesUrls.ImageOjdOne} height={35}/>
                                    </div>
                                    <div style={{textAlign: 'center', fontSize: '20px'}}>{selectedEtude?.Name}</div>
                                </div>
                                <div className="column">
                                    
                                </div>
                                <div className="columnVerticalGraphs">
                                    <VerticalGraph height={240} titleHidden={false} waveId={props.waveId} supportId={props.support.ID} questionIds={[props.groupIds.ids[1].id, props.groupIds.ids[2].id]} />
                                </div>
                                <div className="column" style={{ height: '330px' }}>
                                    <div className="sunburstContainer">
                                        <div style={{ width: '410px' }}>
                                            <p style={{ textAlign: 'center', marginBottom: '5px' }}>
                                                {props.groupIds?.ids[3].name}
                                            </p>
                                            <HFGraph
                                                questionLabel=""
                                                labelColor="white"
                                                width={200}
                                                height={200}
                                                legendWidth={185}
                                                supportId={props.support.ID}
                                                waveId={props.waveId}
                                                questionId={props.groupIds.ids[3].id}
                                                questionLabelStyle={questionLabelStyle}
                                                Labels={[{ Index: 2, Label: 'Agglo. 20k à 100k hab' }]}
                                            />
                                        </div>
                                        <div style={{ width: '385px' }} >
                                            <p style={{ textAlign: 'center', marginBottom: '5px' }}>
                                                {props.groupIds?.ids[4].name}
                                            </p>
                                            <HFGraph
                                                questionLabel=""
                                                height={200}
                                                width={200}
                                                labelColor="white"
                                                legendWidth={100}
                                                supportId={props.support.ID}
                                                waveId={props.waveId}
                                                questionId={props.groupIds?.ids[4].id}
                                                questionLabelStyle={questionLabelStyle}
                                                Labels={[]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="stats">
                            <div className="row">
                                <div className="graphOJDImg">
                                    <Img src={PublicPicturesUrls.ImageOjd} height={35} />
                                </div>
                                <div style={{paddingLeft: '20px', marginTop: '-1px'}}>
                                    <div className='colsideleft'>
                                        <GraphOJD codeSupport={props.supportCodeTsm} />
                                    </div>
                                    <div className="column graphOjdEvolCol" style={{position: 'relative'}}>
                                        <p className="graphOJDMonthTitle">Diffusion France Payée - Evol. en % (2024-2023)</p>
                                        <GraphOJDMens codeSupport={props.supportCodeTsm} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}