/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OJDMensuel } from '../../data/objects/OJDMensuel';
import { RootState } from '../../redux/reducers/root.reducer';
import { VerticalBarSeries, VerticalBarSeriesPoint, XAxis, YAxis, Hint, FlexibleWidthXYPlot, XYPlot } from 'react-vis';
import { setPrintMode } from '../../redux/actions/print.actions';

interface GraphOJDMensProps {
    codeSupport: string;
}

export const GraphOJDMens = (props: GraphOJDMensProps) => {
    const dispatch = useDispatch();
    const grandeur = "DiffusionPayee";
    const months = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'];

    const [indiceSup, setIndiceSup] = React.useState(0);
    const [data, setData] = React.useState<VerticalBarSeriesPoint[]>([]);
    const [hovered, setHovered] = React.useState<VerticalBarSeriesPoint | null>(null);
    const [yDomain, setYDomain] = React.useState<number[]>([]);
    const [yDomain2, setYDomain2] = React.useState<number[]>([]);

    const ojdMensuels = useSelector((state: RootState) => state.ojd.ojdMensuels);
    const theme = useSelector((state: RootState) => state.theme.colors);
    const printMode = useSelector((state: RootState) => state.print.printMode);

    const getData = (year: number, month: number): OJDMensuel | undefined => {
        for (let i = 0; i !== ojdMensuels.length; i++) {
            const element = ojdMensuels[i];

            if (element.Year === year && element.Month === month)
                return (element);
        }

        return (undefined);
    }

    React.useEffect(() => {
        if (ojdMensuels.length > 0) {
            let tmpData: VerticalBarSeriesPoint[] = [];
            let tmpIndiceInf = 0;
            let tmpIndiceSup = 0;
            let tmpIndice = 0;
            for (let index = 1; index <= 12; index++) {
                const current = getData(2024, index);
                const previous = getData(2023, index);

                if (current && previous) {
                    let value = current[grandeur] / previous[grandeur] * 100;
                    let indiceTmp = 100 - value;
                    let absIndiceTmp = Math.abs(100 - value);

                    if (indiceTmp < 0 && absIndiceTmp > tmpIndiceInf)
                        tmpIndiceInf = absIndiceTmp;
                    else if (indiceTmp > 0 && absIndiceTmp > tmpIndiceSup)
                        tmpIndiceSup = absIndiceTmp;

                    if (absIndiceTmp > tmpIndice)
                        tmpIndice = absIndiceTmp;

                    let toPush: VerticalBarSeriesPoint = {
                        x: index,
                        y: value - 100
                    };

                    if (value > 100)
                        toPush.color = theme?.ColorLastGraph;

                    tmpData.push(toPush);
                }
                else
                    tmpData.push({ x: index, y: 0 });
            }

            tmpIndiceSup *= 1.05;
            tmpIndiceInf *= 1.05;

            for (let d = 0; d < tmpData.length; d++) {
                tmpData[d].y -= tmpIndiceSup;
            }

            setData(tmpData);
            setIndiceSup(tmpIndiceSup);
            setYDomain([tmpIndiceSup * -1, tmpIndiceInf]);
            setYDomain2([tmpIndiceSup * -2, tmpIndiceInf - tmpIndiceSup]);
        }
    }, [ojdMensuels]);

    React.useEffect(() => {
        const handleBeforePrint = () => {
            dispatch(setPrintMode(true));
        };

        const handleAfterPrint = () => {
            dispatch(setPrintMode(false));
        };

        window.addEventListener('beforeprint', handleBeforePrint);
        window.addEventListener('afterprint', handleAfterPrint);

        return () => {
            window.removeEventListener('beforeprint', handleBeforePrint);
            window.removeEventListener('afterprint', handleAfterPrint);
        };
    }, []);

    return (
        <div className="graphOjdEvol">
            {printMode ?
                <XYPlot className="verticalbarseriesexample" {...{ yDomain }} xType="ordinal" height={250} width={870}>
                    <XAxis tickSize={0} style={{ line: { strokeWidth: '0.1px' } }} tickFormat={v => {
                        return (months[v - 1]);
                    }} />
                    <YAxis tickSize={0} style={{ line: { strokeWidth: '0.1px' } }} tickTotal={3} tickFormat={v => {
                        return (v + 100);
                    }} />
                    <VerticalBarSeries
                        colorType="literal"
                        {...{ yDomain: yDomain2 }}
                        color={theme?.ColorGraph}
                        data={data}
                        barWidth={0.85}
                        onValueMouseOver={datapoint => { setHovered(datapoint) }}
                        onValueMouseOut={() => setHovered(null)}
                    />
                    {hovered &&
                        <Hint value={{ x: hovered.x, y: hovered.y + indiceSup }}>
                            <div className="tipsgraphojd">
                                {(hovered.y + indiceSup + 100).toFixed(2) + "%"}
                            </div>
                        </Hint>
                    }
                </XYPlot>
                :
                <FlexibleWidthXYPlot className="verticalbarseriesexample" {...{ yDomain }} xType="ordinal" height={250}>
                    <XAxis tickSize={0} style={{ line: { strokeWidth: '0.5px' } }} tickFormat={v => {
                        return (months[v - 1]);
                    }} />
                    <YAxis tickSize={0} style={{ line: { strokeWidth: '0.5px' } }} tickTotal={3} tickFormat={v => {
                        return (v + 100);
                    }} />
                    <VerticalBarSeries
                        colorType="literal"
                        {...{ yDomain: yDomain2 }}
                        color={theme?.ColorGraph}
                        data={data}
                        barWidth={0.85}
                        onValueMouseOver={datapoint => { setHovered(datapoint) }}
                        onValueMouseOut={() => setHovered(null)}
                    />
                    {hovered &&
                        <Hint value={{ x: hovered.x, y: hovered.y + indiceSup }}>
                            <div className="tipsgraphojd">
                                {(hovered.y + indiceSup + 100).toFixed(2) + "%"}
                            </div>
                        </Hint>
                    }
                </FlexibleWidthXYPlot>
            }
        </div>
    );
}