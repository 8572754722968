import Img from 'react-image-resizer';
import { AdwantedImageUrls } from '../../utility/Constants';

interface CouvertureSupportProps {
    couvPath: string | undefined;
}

export const CouvertureSupport = (props: CouvertureSupportProps) => {
    return (
        <div className="reactImg">
            <Img src={props.couvPath ? (props.couvPath.includes("https") ? props.couvPath : AdwantedImageUrls.CouvertureUrl + props.couvPath) : ""} height={250} />
        </div>
    );
}